import React from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  return (
    <Nav
      style={{ margin: "40px 40px" }}
      className="justify-content-center mb-4"
    >
      <Nav.Item>
        {step1 ? (
          <LinkContainer to="/login">
            <Nav.Link>Sign In</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Sign In</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step2 ? (
          <LinkContainer to="/shipping">
            <Nav.Link>Shipping</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Shipping</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step3 ? (
          <LinkContainer to="/payment">
            <Nav.Link>Payment</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Payment</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step4 ? (
          <LinkContainer to="/placeorder">
            <Nav.Link>Place Order</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Place Order</Nav.Link>
        )}
      </Nav.Item>
    </Nav>
  );
};

export default CheckoutSteps;

// import React from "react";
// import { Stepper, Step, StepLabel } from "@mui/material";
// import { Link } from "react-router-dom";

// const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
//   const steps = [
//     { label: "Sign In", link: "/login", completed: step1 },
//     { label: "Shipping", link: "/shipping", completed: step2 },
//     { label: "Payment", link: "/payment", completed: step3 },
//     { label: "Place Order", link: "/placeorder", completed: step4 },
//   ];

//   return (
//     <Stepper alternativeLabel>
//       {steps.map((step, index) => (
//         <Step key={index} active={step.completed} completed={step.completed}>
//           <StepLabel>
//             {step.completed ? (
//               <Link
//                 to={step.link}
//                 style={{ textDecoration: "none", color: "inherit" }}
//               >
//                 {step.label}
//               </Link>
//             ) : (
//               <span style={{ color: "rgba(0, 0, 0, 0.38)" }}>{step.label}</span>
//             )}
//           </StepLabel>
//         </Step>
//       ))}
//     </Stepper>
//   );
// };

// export default CheckoutSteps;
