import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { bulkUploadProducts } from "../actions/productActions";
import { useSelector } from "react-redux";
import Message from "../components/Message";

const BulkUploadScreen = () => {
  const [zipFile, setZipFile] = useState(null);
  const productBulkUpload = useSelector(
    (state) => state.productBulkUploadReducer
  );
  const { loading, success, error } = productBulkUpload;

  const dispatch = useDispatch();

  // const uploadHandler = (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append("file", csvFile);

  //   dispatch(bulkUploadProducts(formData));
  // };
  const uploadHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", zipFile); // The ZIP file containing CSV and images

    dispatch(bulkUploadProducts(formData));
  };

  return (
    <>
      {success && (
        <Message variant="success">Products Uploaded successfully</Message>
      )}
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h1>Bulk Upload Products</h1>
          {/* <Form onSubmit={uploadHandler}>
            <Form.Group controlId="csvFile">
              <Form.Label>Upload CSV File</Form.Label>
              <Form.Control
                type="file"
                accept=".csv"
                onChange={(e) => setCsvFile(e.target.files[0])}
              />
            </Form.Group>
            <Button type="submit" variant="primary">
              Upload
            </Button>
          </Form> */}
          <Form onSubmit={uploadHandler}>
            <Form.Group controlId="zipFile">
              <Form.Label>Upload ZIP File (CSV + Images)</Form.Label>
              <Form.Control
                type="file"
                accept=".zip"
                onChange={(e) => setZipFile(e.target.files[0])}
              />
            </Form.Group>
            <Button type="submit" variant="primary">
              Upload
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default BulkUploadScreen;
