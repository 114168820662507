import React, { useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Paginate from "../components/Paginate";
import {
  listProducts,
  deleteProduct,
  createProduct,
} from "../actions/productActions";
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_DELETE_RESET,
} from "../constants/productConstants";
import { useNavigate, useParams } from "react-router-dom";
import DynamicAlert from "../components/DynamicAlert";

const ProductListScreen = () => {
  const pageNumber = useParams() || 1;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      navigate("/login");
    }

    if (successCreate) {
      navigate(`/admin/product/${createdProduct._id}/edit`);
    } else {
      dispatch(listProducts("", pageNumber));
    }
    dispatch({ type: PRODUCT_CREATE_RESET });
    // dispatch({ type: PRODUCT_DELETE_RESET });
  }, [
    dispatch,
    navigate,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
    pageNumber,
  ]);

  const deleteHandler = (id) => {
    setShowAlert(true);
    setProductIdToDelete(id);
  };
  const deleteProducts = () => {
    dispatch(deleteProduct(productIdToDelete));
    setShowAlert(false);
  };

  const createProductHandler = () => {
    dispatch(createProduct());
  };
  console.log("success", successDelete);

  return (
    <div style={{ margin: "40px 40px" }}>
      {successDelete && (
        <Message variant="danger">Product Deleted successfully.</Message>
      )}
      {showAlert && (
        <DynamicAlert
          show={showAlert}
          title={"Delete Product"}
          message={"Are you sure you want to delete?"}
          onOk={() => deleteProducts()}
          onCancel={() => setShowAlert(false)}
          okText="OK"
          cancelText="Cancel"
        />
      )}
      <Row className="align-items-center">
        <Col>
          <h1>Products</h1>
        </Col>
        <Col className="text-right">
          <Button
            type="submit"
            variant="primary"
            onClick={() => navigate("/admin/bulk-upload")}
            style={{ margin: "10px" }}
          >
            Bulk Upload
          </Button>
          <Button className="my-3" onClick={createProductHandler}>
            <i className="fas fa-plus"></i> Create Product
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant="danger">{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>PRICE</th>
                <th>CATEGORY</th>
                <th>BRAND</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product._id}>
                  <td>{product._id}</td>
                  <td>{product.name}</td>
                  <td>INR {product.price}</td>
                  <td>{product.category}</td>
                  <td>{product.brand}</td>
                  <td>
                    <LinkContainer to={`/admin/product/${product._id}/edit`}>
                      <Button variant="light" className="btn-sm">
                        <i className="fas fa-edit"></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(product._id)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate pages={pages} page={page} isAdmin={true} />
        </div>
      )}
    </div>
  );
};

export default ProductListScreen;

// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import {
//   Button,
//   Typography,
//   Grid,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   CircularProgress,
// } from "@mui/material";
// import { Link } from "react-router-dom";
// import {
//   listProducts,
//   deleteProduct,
//   createProduct,
// } from "../actions/productActions";
// import { PRODUCT_CREATE_RESET } from "../constants/productConstants";
// import Message from "../components/Message";
// import Paginate from "../components/Paginate";

// const ProductListScreen = () => {
//   const { pageNumber = 1 } = useParams();
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const productList = useSelector((state) => state.productList);
//   const { loading, error, products, page, pages } = productList;

//   const productDelete = useSelector((state) => state.productDelete);
//   const {
//     loading: loadingDelete,
//     error: errorDelete,
//     success: successDelete,
//   } = productDelete;

//   const productCreate = useSelector((state) => state.productCreate);
//   const {
//     loading: loadingCreate,
//     error: errorCreate,
//     success: successCreate,
//     product: createdProduct,
//   } = productCreate;

//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;

//   useEffect(() => {
//     dispatch({ type: PRODUCT_CREATE_RESET });

//     if (!userInfo || !userInfo.isAdmin) {
//       navigate("/login");
//     }

//     if (successCreate) {
//       navigate(`/admin/product/${createdProduct._id}/edit`);
//     } else {
//       dispatch(listProducts("", pageNumber));
//     }
//   }, [
//     dispatch,
//     navigate,
//     userInfo,
//     successDelete,
//     successCreate,
//     createdProduct,
//     pageNumber,
//   ]);

//   const deleteHandler = (id) => {
//     if (window.confirm("Are you sure")) {
//       dispatch(deleteProduct(id));
//     }
//   };

//   const createProductHandler = () => {
//     dispatch(createProduct());
//   };

//   return (
//     <>
//       <Grid
//         container
//         spacing={2}
//         alignItems="center"
//         justifyContent="space-between"
//       >
//         <Grid item>
//           <Typography variant="h4">Products</Typography>
//         </Grid>
//         <Grid item>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={createProductHandler}
//           >
//             Create Product
//           </Button>
//         </Grid>
//       </Grid>
//       {loadingDelete && <CircularProgress />}
//       {errorDelete && <Message variant="error">{errorDelete}</Message>}
//       {loadingCreate && <CircularProgress />}
//       {errorCreate && <Message variant="error">{errorCreate}</Message>}
//       {loading ? (
//         <CircularProgress />
//       ) : error ? (
//         <Message variant="error">{error}</Message>
//       ) : (
//         <>
//           <TableContainer component={Paper} style={{ marginTop: "16px" }}>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell>ID</TableCell>
//                   <TableCell>NAME</TableCell>
//                   <TableCell>PRICE</TableCell>
//                   <TableCell>CATEGORY</TableCell>
//                   <TableCell>BRAND</TableCell>
//                   <TableCell></TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {products.map((product) => (
//                   <TableRow key={product._id}>
//                     <TableCell>{product._id}</TableCell>
//                     <TableCell>{product.name}</TableCell>
//                     <TableCell>${product.price}</TableCell>
//                     <TableCell>{product.category}</TableCell>
//                     <TableCell>{product.brand}</TableCell>
//                     <TableCell>
//                       <Link to={`/admin/product/${product._id}/edit`}>
//                         <Button
//                           variant="outlined"
//                           color="primary"
//                           style={{ marginRight: "8px" }}
//                         >
//                           Edit
//                         </Button>
//                       </Link>
//                       <Button
//                         variant="outlined"
//                         color="error"
//                         onClick={() => deleteHandler(product._id)}
//                       >
//                         Delete
//                       </Button>
//                     </TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//           <Paginate pages={pages} page={page} isAdmin={true} />
//         </>
//       )}
//     </>
//   );
// };

// export default ProductListScreen;
