import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { getUserDetails, updateUser } from "../actions/userActions";
import { USER_UPDATE_RESET } from "../constants/userConstants";

const UserEditScreen = () => {
  const { id: userId } = useParams();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET });
      navigate("/admin/userlist");
    } else {
      if (!user.name || user._id !== userId) {
        dispatch(getUserDetails(userId));
      } else {
        setName(user.name);
        setEmail(user.email);
        setIsAdmin(user.isAdmin);
      }
    }
  }, [dispatch, navigate, userId, user, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateUser({ _id: userId, name, email, isAdmin }));
  };

  return (
    <div style={{ margin: "0px 40px" }}>
      <Link to="/admin/userlist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit User</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="isadmin">
              <Form.Check
                type="checkbox"
                label="Is Admin"
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              ></Form.Check>
            </Form.Group>

            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </div>
  );
};

export default UserEditScreen;

// import React, { useState, useEffect } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import {
//   Typography,
//   TextField,
//   Checkbox,
//   FormControlLabel,
//   Button,
//   Paper,
// } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import Message from "../components/Message";
// import Loader from "../components/Loader";
// import FormContainer from "../components/FormContainer";
// import { getUserDetails, updateUser } from "../actions/userActions";
// import { USER_UPDATE_RESET } from "../constants/userConstants";

// const UserEditScreen = () => {
//   const { id: userId } = useParams();

//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [isAdmin, setIsAdmin] = useState(false);

//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const userDetails = useSelector((state) => state.userDetails);
//   const { loading, error, user } = userDetails;

//   const userUpdate = useSelector((state) => state.userUpdate);
//   const {
//     loading: loadingUpdate,
//     error: errorUpdate,
//     success: successUpdate,
//   } = userUpdate;

//   useEffect(() => {
//     if (successUpdate) {
//       dispatch({ type: USER_UPDATE_RESET });
//       navigate("/admin/userlist");
//     } else {
//       if (!user.name || user._id !== userId) {
//         dispatch(getUserDetails(userId));
//       } else {
//         setName(user.name);
//         setEmail(user.email);
//         setIsAdmin(user.isAdmin);
//       }
//     }
//   }, [dispatch, navigate, userId, user, successUpdate]);

//   const submitHandler = (e) => {
//     e.preventDefault();
//     dispatch(updateUser({ _id: userId, name, email, isAdmin }));
//   };

//   return (
//     <>
//       <Link to="/admin/userlist">
//         <Button
//           variant="contained"
//           color="secondary"
//           style={{ margin: "16px 0" }}
//         >
//           Go Back
//         </Button>
//       </Link>
//       <FormContainer>
//         <Typography variant="h4" gutterBottom>
//           Edit User
//         </Typography>
//         {loadingUpdate && <Loader />}
//         {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
//         {loading ? (
//           <Loader />
//         ) : error ? (
//           <Message variant="danger">{error}</Message>
//         ) : (
//           <Paper style={{ padding: "20px" }}>
//             <form onSubmit={submitHandler}>
//               <TextField
//                 fullWidth
//                 margin="normal"
//                 label="Name"
//                 variant="outlined"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 required
//               />
//               <TextField
//                 fullWidth
//                 margin="normal"
//                 label="Email Address"
//                 variant="outlined"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 required
//               />
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={isAdmin}
//                     onChange={(e) => setIsAdmin(e.target.checked)}
//                   />
//                 }
//                 label="Is Admin"
//               />
//               <Button
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 fullWidth
//                 style={{ marginTop: "16px" }}
//               >
//                 Update
//               </Button>
//             </form>
//           </Paper>
//         )}
//       </FormContainer>
//     </>
//   );
// };

// export default UserEditScreen;
