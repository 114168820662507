import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";
import { saveShippingAddress } from "../actions/cartActions";
import { useNavigate } from "react-router-dom";

const ShippingScreen = () => {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;
  const navigate = useNavigate();

  const [address, setAddress] = useState(shippingAddress.address);
  const [city, setCity] = useState(shippingAddress.city);
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
  const [country, setCountry] = useState(shippingAddress.country);

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveShippingAddress({ address, city, postalCode, country }));
    navigate("/payment");
  };

  return (
    <FormContainer style={{ margin: "40px 40px" }}>
      <CheckoutSteps step1 step2 />
      <h1>Shipping</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="address">
          <Form.Label>Address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter address"
            value={address}
            required
            onChange={(e) => setAddress(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="city">
          <Form.Label>City</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter city"
            value={city}
            required
            onChange={(e) => setCity(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="postalCode">
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter postal code"
            value={postalCode}
            required
            onChange={(e) => setPostalCode(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="country">
          <Form.Label>Country</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter country"
            value={country}
            required
            onChange={(e) => setCountry(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type="submit" variant="primary" style={{ marginTop: "10px" }}>
          Continue
        </Button>
      </Form>
    </FormContainer>
  );
};

export default ShippingScreen;

// import React, { useState } from "react";
// import { Grid, Typography, TextField, Button, Paper } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { saveShippingAddress } from "../actions/cartActions";
// import CheckoutSteps from "../components/CheckoutSteps";
// import FormContainer from "../components/FormContainer";

// const ShippingScreen = () => {
//   const cart = useSelector((state) => state.cart);
//   const { shippingAddress } = cart;
//   const navigate = useNavigate();

//   const [address, setAddress] = useState(shippingAddress.address || "");
//   const [city, setCity] = useState(shippingAddress.city || "");
//   const [postalCode, setPostalCode] = useState(
//     shippingAddress.postalCode || ""
//   );
//   const [country, setCountry] = useState(shippingAddress.country || "");

//   const dispatch = useDispatch();

//   const submitHandler = (e) => {
//     e.preventDefault();
//     dispatch(saveShippingAddress({ address, city, postalCode, country }));
//     navigate("/payment");
//   };

//   return (
//     <FormContainer>
//       <CheckoutSteps step1 step2 />
//       <Typography variant="h4" gutterBottom>
//         Shipping
//       </Typography>
//       <Paper style={{ padding: "20px" }}>
//         <form onSubmit={submitHandler}>
//           <TextField
//             fullWidth
//             margin="normal"
//             label="Address"
//             variant="outlined"
//             value={address}
//             required
//             onChange={(e) => setAddress(e.target.value)}
//           />
//           <TextField
//             fullWidth
//             margin="normal"
//             label="City"
//             variant="outlined"
//             value={city}
//             required
//             onChange={(e) => setCity(e.target.value)}
//           />
//           <TextField
//             fullWidth
//             margin="normal"
//             label="Postal Code"
//             variant="outlined"
//             value={postalCode}
//             required
//             onChange={(e) => setPostalCode(e.target.value)}
//           />
//           <TextField
//             fullWidth
//             margin="normal"
//             label="Country"
//             variant="outlined"
//             value={country}
//             required
//             onChange={(e) => setCountry(e.target.value)}
//           />
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             fullWidth
//             style={{ marginTop: "16px" }}
//           >
//             Continue
//           </Button>
//         </form>
//       </Paper>
//     </FormContainer>
//   );
// };

// export default ShippingScreen;
