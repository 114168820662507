import React from "react";

const TermsOfService = () => {
  return (
    <div style={{ margin: "0 40px" }}>
      <h1>Terms of Service</h1>
      <h2>Overview</h2>
      <p>
        This website is operated by Techkunstler. Throughout the site, the terms
        “we”, “us” and “our” refer to Techkunstler. Techkunstler offers this
        website, including all information, tools, and services available from
        this site to you, the user, conditioned upon your acceptance of all
        terms, conditions, policies, and notices stated here.
      </p>
      <p>
        By visiting our site and/or purchasing something from us, you engage in
        our “Service” and agree to be bound by the following terms and
        conditions (“Terms of Service”, “Terms”), including those additional
        terms and conditions and policies referenced herein and/or available by
        hyperlink.
      </p>
      <p>
        These Terms of Service apply to all users of the site, including without
        limitation users who are browsers, vendors, customers, merchants, and/or
        contributors of content. Please read these Terms of Service carefully
        before accessing or using our website. By accessing or using any part of
        the site, you agree to be bound by these Terms of Service. If you do not
        agree to all the terms and conditions of this agreement, then you may
        not access the website or use any services. If these Terms of Service
        are considered an offer, acceptance is expressly limited to these Terms
        of Service.
      </p>

      <h2>Section 1 - Online Store Terms</h2>
      <p>
        By agreeing to these Terms of Service, you represent that you are at
        least the age of majority in your state or province of residence, or
        that you are the age of majority in your state or province of residence
        and you have given us your consent to allow any of your minor dependents
        to use this site.
      </p>

      <h2>Section 2 - General Conditions</h2>
      <p>
        We reserve the right to refuse service to anyone for any reason at any
        time. You understand that your content (not including credit card
        information), may be transferred unencrypted and involve (a)
        transmissions over various networks; and (b) changes to conform and
        adapt to technical requirements of connecting networks or devices.
        Credit card information is always encrypted during transfer over
        networks.
      </p>

      <h2>Section 3 - Accuracy, Completeness, and Timeliness of Information</h2>
      <p>
        We are not responsible if information made available on this site is not
        accurate, complete, or current. The material on this site is provided
        for general information only and should not be relied upon or used as
        the sole basis for making decisions without consulting primary, more
        accurate, more complete, or more timely sources of information.
      </p>

      <h2>Section 4 - Modifications to the Service and Prices</h2>
      <p>
        Prices for our products are subject to change without notice. We reserve
        the right at any time to modify or discontinue the Service (or any part
        or content thereof) without notice at any time. We shall not be liable
        to you or to any third-party for any modification, price change,
        suspension, or discontinuance of the Service.
      </p>

      <h2>Section 5 - Products or Services</h2>
      <p>
        Certain products or services may be available exclusively online through
        the website. These products or services may have limited quantities and
        are subject to return or exchange only according to our Return Policy.
      </p>

      <h2>Section 6 - Accuracy of Billing and Account Information</h2>
      <p>
        We reserve the right to refuse any order you place with us. We may, in
        our sole discretion, limit or cancel quantities purchased per person,
        per household, or per order.
      </p>

      <h2>Section 7 - Optional Tools</h2>
      <p>
        We may provide you with access to third-party tools over which we
        neither monitor nor have any control nor input.
      </p>

      <h2>Section 8 - Third-Party Links</h2>
      <p>
        Certain content, products, and services available via our Service may
        include materials from third parties. Third-party links on this site may
        direct you to third-party websites that are not affiliated with us.
      </p>

      <h2>Section 9 - User Comments, Feedback, and Other Submissions</h2>
      <p>
        If, at our request, you send certain specific submissions or without a
        request from us you send creative ideas, suggestions, proposals, plans,
        or other materials, whether online, by email, by postal mail, or
        otherwise (collectively, 'comments'), you agree that we may, at any
        time, without restriction, edit, copy, publish, distribute, translate
        and otherwise use in any medium any comments that you forward to us.
      </p>

      <h2>Section 10 - Personal Information</h2>
      <p>
        Your submission of personal information through the store is governed by
        our Privacy Policy.
      </p>

      <h2>Section 11 - Errors, Inaccuracies, and Omissions</h2>
      <p>
        Occasionally there may be information on our site or in the Service that
        contains typographical errors, inaccuracies or omissions that may relate
        to product descriptions, pricing, promotions, offers, product shipping
        charges, transit times and availability.
      </p>

      <h2>Section 12 - Prohibited Uses</h2>
      <p>
        In addition to other prohibitions as set forth in the Terms of Service,
        you are prohibited from using the site or its content for any unlawful
        purpose.
      </p>

      {/* Add additional sections as needed */}
    </div>
  );
};

export default TermsOfService;
