import React, { useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listUsers, deleteUser } from "../actions/userActions";
import { useNavigate } from "react-router-dom";
import DynamicAlert from "../components/DynamicAlert";

const UserListScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;
  const [showAlert, setShowAlert] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listUsers());
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, successDelete, userInfo]);

  const deleteHandler = (id) => {
    setShowAlert(true);
    setUserIdToDelete(id);
  };
  const handleDeleteUser = () => {
    dispatch(deleteUser(userIdToDelete));
  };

  return (
    <div style={{ margin: "40px 40px" }}>
      {showAlert && (
        <DynamicAlert
          show={showAlert}
          title={"Delete Product"}
          message={"Are you sure you want to delete the user?"}
          onOk={() => handleDeleteUser()}
          onCancel={() => setShowAlert(false)}
          okText="OK"
          cancelText="Cancel"
        />
      )}
      <h1 style={{ margin: "0px 40px" }}>Users</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div style={{ margin: "0px 40px" }}>
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>ADMIN</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user._id}</td>
                  <td>{user.name}</td>
                  <td>
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </td>
                  <td>
                    {user.isAdmin ? (
                      <i
                        className="fas fa-check"
                        style={{ color: "green" }}
                      ></i>
                    ) : (
                      <i className="fas fa-times" style={{ color: "red" }}></i>
                    )}
                  </td>
                  <td>
                    <LinkContainer to={`/admin/user/${user._id}/edit`}>
                      <Button variant="light" className="btn-sm">
                        <i className="fa fa-edit"></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(user._id)}
                    >
                      <i className="fa fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default UserListScreen;

// import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import { Table, IconButton, Typography, Paper } from "@mui/material";
// import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
// import { useDispatch, useSelector } from "react-redux";
// import Message from "../components/Message";
// import Loader from "../components/Loader";
// import { listUsers, deleteUser } from "../actions/userActions";
// import { useNavigate } from "react-router-dom";

// const UserListScreen = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const userList = useSelector((state) => state.userList);
//   const { loading, error, users } = userList;

//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;

//   const userDelete = useSelector((state) => state.userDelete);
//   const { success: successDelete } = userDelete;

//   useEffect(() => {
//     if (userInfo && userInfo.isAdmin) {
//       dispatch(listUsers());
//     } else {
//       navigate("/login");
//     }
//   }, [dispatch, navigate, successDelete, userInfo]);

//   const deleteHandler = (id) => {
//     if (window.confirm("Are you sure you want to delete this user?")) {
//       dispatch(deleteUser(id));
//     }
//   };

//   return (
//     <>
//       <Typography variant="h4" gutterBottom>
//         Users
//       </Typography>
//       {loading ? (
//         <Loader />
//       ) : error ? (
//         <Message variant="danger">{error}</Message>
//       ) : (
//         <Paper style={{ padding: "20px" }}>
//           <Table>
//             <thead>
//               <tr>
//                 <th>ID</th>
//                 <th>NAME</th>
//                 <th>EMAIL</th>
//                 <th>ADMIN</th>
//                 <th>ACTIONS</th>
//               </tr>
//             </thead>
//             <tbody>
//               {users.map((user) => (
//                 <tr key={user._id}>
//                   <td>{user._id}</td>
//                   <td>{user.name}</td>
//                   <td>
//                     <a href={`mailto:${user.email}`}>{user.email}</a>
//                   </td>
//                   <td>
//                     {user.isAdmin ? (
//                       <Typography color="success.main">Yes</Typography>
//                     ) : (
//                       <Typography color="error.main">No</Typography>
//                     )}
//                   </td>
//                   <td>
//                     <IconButton
//                       component={Link}
//                       to={`/admin/user/${user._id}/edit`}
//                       color="primary"
//                     >
//                       <EditIcon />
//                     </IconButton>
//                     <IconButton
//                       color="error"
//                       onClick={() => deleteHandler(user._id)}
//                     >
//                       <DeleteIcon />
//                     </IconButton>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//         </Paper>
//       )}
//     </>
//   );
// };

// export default UserListScreen;
