import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { listProductDetails, updateProduct } from "../actions/productActions";
import { PRODUCT_UPDATE_RESET } from "../constants/productConstants";

const ProductEditScreen = () => {
  const { id: productId } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [image, setImage] = useState("");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [countInStock, setCountInStock] = useState(0);
  const [description, setDescription] = useState("");
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      navigate("/admin/productlist");
    } else {
      if (!product.name || product._id !== productId) {
        dispatch(listProductDetails(productId));
      } else {
        setName(product.name);
        setPrice(product.price);
        setImage(product.image);
        setBrand(product.brand);
        setCategory(product.category);
        setCountInStock(product.countInStock);
        setDescription(product.description);
      }
    }
  }, [dispatch, navigate, productId, product, successUpdate]);

  // const uploadFileHandler = async (e) => {
  //   const file = e.target.files[0];
  //   const formData = new FormData();
  //   formData.append("image", file);
  //   setUploading(true);

  //   try {
  //     const config = {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     };

  //     const { data } = await axios.post("/api/upload", formData, config);

  //     setImage(data);
  //     setUploading(false);
  //   } catch (error) {
  //     console.error(error);
  //     setUploading(false);
  //   }
  // };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("image", file);

    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        "/api/upload/single-upload",
        formData,
        config
      );

      setImage(data.filePath); // Use the file path returned by the server
      setUploading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProduct({
        _id: productId,
        name,
        price,
        image,
        brand,
        category,
        description,
        countInStock,
      })
    );
  };
  console.log("loading and error", loading, error);

  return (
    <>
      <Link to="/admin/productlist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Product</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <Form onSubmit={submitHandler}>
              {console.log("in here")}

              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="price">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="image">
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter image url"
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                ></Form.Control>
                <Form.Control
                  type="file"
                  id="image-file"
                  label="Choose File"
                  custom
                  onChange={uploadFileHandler}
                ></Form.Control>
                {uploading && <Loader />}
              </Form.Group>

              <Form.Group controlId="brand">
                <Form.Label>Brand</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter brand"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="countInStock">
                <Form.Label>Count In Stock</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter countInStock"
                  value={countInStock}
                  onChange={(e) => setCountInStock(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="category">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Button
                type="submit"
                variant="primary"
                style={{ margin: "10px 0px" }}
              >
                Update
              </Button>
            </Form>
          </>
        )}
      </FormContainer>
    </>
  );
};

export default ProductEditScreen;

// import axios from "axios";
// import React, { useState, useEffect } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import {
//   Button,
//   TextField,
//   Typography,
//   Grid,
//   Paper,
//   CircularProgress,
// } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import Message from "../components/Message";
// import FormContainer from "../components/FormContainer";
// import { listProductDetails, updateProduct } from "../actions/productActions";
// import { PRODUCT_UPDATE_RESET } from "../constants/productConstants";

// const ProductEditScreen = () => {
//   const { id: productId } = useParams();
//   const navigate = useNavigate();

//   const [name, setName] = useState("");
//   const [price, setPrice] = useState(0);
//   const [image, setImage] = useState("");
//   const [brand, setBrand] = useState("");
//   const [category, setCategory] = useState("");
//   const [countInStock, setCountInStock] = useState(0);
//   const [description, setDescription] = useState("");
//   const [uploading, setUploading] = useState(false);

//   const dispatch = useDispatch();

//   const productDetails = useSelector((state) => state.productDetails);
//   const { loading, error, product } = productDetails;

//   const productUpdate = useSelector((state) => state.productUpdate);
//   const {
//     loading: loadingUpdate,
//     error: errorUpdate,
//     success: successUpdate,
//   } = productUpdate;

//   useEffect(() => {
//     if (successUpdate) {
//       dispatch({ type: PRODUCT_UPDATE_RESET });
//       navigate("/admin/productlist");
//     } else {
//       if (!product.name || product._id !== productId) {
//         dispatch(listProductDetails(productId));
//       } else {
//         setName(product.name);
//         setPrice(product.price);
//         setImage(product.image);
//         setBrand(product.brand);
//         setCategory(product.category);
//         setCountInStock(product.countInStock);
//         setDescription(product.description);
//       }
//     }
//   }, [dispatch, navigate, productId, product, successUpdate]);

//   const uploadFileHandler = async (e) => {
//     const file = e.target.files[0];
//     if (!file) {
//       console.error("No file selected");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("image", file);

//     setUploading(true);

//     try {
//       const config = {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       };

//       const { data } = await axios.post("/api/upload", formData, config);

//       setImage(data.filePath); // Use the file path returned by the server
//       setUploading(false);
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       setUploading(false);
//     }
//   };

//   const submitHandler = (e) => {
//     e.preventDefault();
//     dispatch(
//       updateProduct({
//         _id: productId,
//         name,
//         price,
//         image,
//         brand,
//         category,
//         description,
//         countInStock,
//       })
//     );
//   };

//   return (
//     <>
//       <Link
//         to="/admin/productlist"
//         style={{
//           textDecoration: "none",
//           marginBottom: "16px",
//           display: "inline-block",
//         }}
//       >
//         <Button variant="outlined">Go Back</Button>
//       </Link>
//       <FormContainer>
//         <Typography variant="h4" gutterBottom>
//           Edit Product
//         </Typography>
//         {loadingUpdate && <CircularProgress />}
//         {errorUpdate && <Message variant="error">{errorUpdate}</Message>}
//         {loading ? (
//           <CircularProgress />
//         ) : error ? (
//           <Message variant="error">{error}</Message>
//         ) : (
//           <>
//             <Button
//               variant="contained"
//               color="primary"
//               style={{ marginBottom: "16px" }}
//               onClick={() => navigate("/admin/bulk-upload")}
//             >
//               Bulk Upload
//             </Button>
//             <Paper elevation={3} style={{ padding: "16px" }}>
//               <form onSubmit={submitHandler}>
//                 <Grid container spacing={3}>
//                   <Grid item xs={12} sm={6}>
//                     <TextField
//                       label="Name"
//                       variant="outlined"
//                       fullWidth
//                       value={name}
//                       onChange={(e) => setName(e.target.value)}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <TextField
//                       label="Price"
//                       type="number"
//                       variant="outlined"
//                       fullWidth
//                       value={price}
//                       onChange={(e) => setPrice(e.target.value)}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <TextField
//                       label="Image URL"
//                       variant="outlined"
//                       fullWidth
//                       value={image}
//                       onChange={(e) => setImage(e.target.value)}
//                     />
//                     <input
//                       type="file"
//                       id="image-file"
//                       style={{ marginTop: "16px" }}
//                       onChange={uploadFileHandler}
//                     />
//                     {uploading && (
//                       <CircularProgress style={{ marginTop: "16px" }} />
//                     )}
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <TextField
//                       label="Brand"
//                       variant="outlined"
//                       fullWidth
//                       value={brand}
//                       onChange={(e) => setBrand(e.target.value)}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <TextField
//                       label="Count In Stock"
//                       type="number"
//                       variant="outlined"
//                       fullWidth
//                       value={countInStock}
//                       onChange={(e) => setCountInStock(e.target.value)}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <TextField
//                       label="Category"
//                       variant="outlined"
//                       fullWidth
//                       value={category}
//                       onChange={(e) => setCategory(e.target.value)}
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <TextField
//                       label="Description"
//                       variant="outlined"
//                       multiline
//                       rows={4}
//                       fullWidth
//                       value={description}
//                       onChange={(e) => setDescription(e.target.value)}
//                     />
//                   </Grid>
//                 </Grid>
//                 <Button
//                   type="submit"
//                   variant="contained"
//                   color="primary"
//                   style={{ marginTop: "16px" }}
//                 >
//                   Update
//                 </Button>
//               </form>
//             </Paper>
//           </>
//         )}
//       </FormContainer>
//     </>
//   );
// };

// export default ProductEditScreen;
