import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const FormContainer = ({ children }) => {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export default FormContainer;

// import React from "react";
// import { Container, Grid, Box } from "@mui/material";

// const FormContainer = ({ children }) => {
//   return (
//     <Container maxWidth="sm">
//       <Grid container justifyContent="center">
//         <Grid item xs={12} sm={8}>
//           <Box sx={{ mt: 3 }}>{children}</Box>
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default FormContainer;
