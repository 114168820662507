import React from "react";

const ReturnScreen = () => {
  return (
    <div style={{ margin: "0 40px" }}>
      <h1>Return/Cancellations</h1>
      <p>
        Instant digital download items don’t accept returns, exchanges or
        cancellations. Please contact at contact@techkunstler.com about any
        problems with your order.
      </p>
    </div>
  );
};

export default ReturnScreen;
