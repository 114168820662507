import React, { useState, useEffect } from "react";
import axios from "axios";
// import { PayPalButton } from "react-paypal-button-v2";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Col, ListGroup, Image, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from "../actions/orderActions";
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from "../constants/orderConstants";

const OrderScreen = () => {
  const { id: orderId } = useParams();

  const [sdkReady, setSdkReady] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!loading) {
    //   Calculate prices
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    );
  }

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }

    const addPayPalScript = async () => {
      const { data: clientId } = await axios.get("/api/config/paypal");
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };

    if (!order || successPay || successDeliver || order._id !== orderId) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(getOrderDetails(orderId));
    } else if (!order.isPaid) {
      if (!window.paypal) {
        addPayPalScript();
      } else {
        setSdkReady(true);
      }
    }
  }, [dispatch, orderId, successPay, successDeliver, order]);

  const successPaymentHandler = (/*paymentResult*/) => {
    // console.log(paymentResult)
    // dispatch(payOrder(orderId,paymentResult))
    console.log("orderId = " + orderId);
    dispatch(payOrder(orderId));
  };

  const deliverHandler = () => {
    dispatch(deliverOrder(order));
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <div style={{ margin: "40px 40px" }}>
      <h1>Order {order._id}</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Shipping</h2>
              <p>
                <strong>Name: </strong> {order.user.name}
              </p>
              <p>
                <strong>Email: </strong>{" "}
                <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              </p>
              <p>
                <strong>Address:</strong>
                {order.shippingAddress.address}, {order.shippingAddress.city}{" "}
                {order.shippingAddress.postalCode},{" "}
                {order.shippingAddress.country}
              </p>
              {order.isDelivered ? (
                <Message variant="success">
                  Delivered on {order.deliveredAt}
                </Message>
              ) : (
                <Message variant="danger">Not Delivered</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <p>
                <strong>Method: </strong>
                {order.paymentMethod}
              </p>
              {order.isPaid ? (
                <Message variant="success">Paid on {order.paidAt}</Message>
              ) : (
                <Message variant="danger">Not Paid</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Order Items</h2>
              {order.orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <ListGroup variant="flush">
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Items</Col>
                  <Col>${order.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Shipping</Col>
                  <Col>${order.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Tax</Col>
                  <Col>${order.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col>${order.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
              {!order.isPaid && (
                <ListGroup.Item>
                  {loadingPay && <Loader />}
                  {!sdkReady ? (
                    <Loader />
                  ) : (
                    // <PayPalButton
                    //   amount={order.totalPrice}
                    //   onSuccess={successPaymentHandler}
                    // />
                    <Button
                      type="button"
                      className="btn-block"
                      onClick={successPaymentHandler}
                    >
                      Done Order
                    </Button>
                  )}
                </ListGroup.Item>
              )}
              {loadingDeliver && <Loader />}
              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                !order.isDelivered && (
                  <ListGroup.Item>
                    <Button
                      type="button"
                      className="btn btn-block"
                      onClick={deliverHandler}
                    >
                      Mark As Delivered
                    </Button>
                  </ListGroup.Item>
                )}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default OrderScreen;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { PayPalButton } from "react-paypal-button-v2";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import {
//   Box,
//   Typography,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemAvatar,
//   Avatar,
//   Card,
//   CardContent,
//   Button,
//   Divider,
//   CircularProgress,
// } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import Message from "../components/Message";
// import {
//   getOrderDetails,
//   payOrder,
//   deliverOrder,
// } from "../actions/orderActions";
// import {
//   ORDER_PAY_RESET,
//   ORDER_DELIVER_RESET,
// } from "../constants/orderConstants";

// const OrderScreen = () => {
//   const { id: orderId } = useParams();
//   const [sdkReady, setSdkReady] = useState(false);

//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const orderDetails = useSelector((state) => state.orderDetails);
//   const { order, loading, error } = orderDetails;

//   const orderPay = useSelector((state) => state.orderPay);
//   const { loading: loadingPay, success: successPay } = orderPay;

//   const orderDeliver = useSelector((state) => state.orderDeliver);
//   const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;

//   if (!loading) {
//     // Calculate prices
//     const addDecimals = (num) => (Math.round(num * 100) / 100).toFixed(2);
//     order.itemsPrice = addDecimals(
//       order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
//     );
//   }

//   useEffect(() => {
//     if (!userInfo) {
//       navigate("/login");
//     }

//     const addPayPalScript = async () => {
//       const { data: clientId } = await axios.get("/api/config/paypal");
//       const script = document.createElement("script");
//       script.type = "text/javascript";
//       script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
//       script.async = true;
//       script.onload = () => {
//         setSdkReady(true);
//       };
//       document.body.appendChild(script);
//     };

//     if (!order || successPay || successDeliver || order._id !== orderId) {
//       dispatch({ type: ORDER_PAY_RESET });
//       dispatch({ type: ORDER_DELIVER_RESET });
//       dispatch(getOrderDetails(orderId));
//     } else if (!order.isPaid) {
//       if (!window.paypal) {
//         addPayPalScript();
//       } else {
//         setSdkReady(true);
//       }
//     }
//   }, [
//     dispatch,
//     orderId,
//     successPay,
//     successDeliver,
//     order,
//     navigate,
//     userInfo,
//   ]);

//   const successPaymentHandler = () => {
//     dispatch(payOrder(orderId));
//   };

//   const deliverHandler = () => {
//     dispatch(deliverOrder(order));
//   };

//   return loading ? (
//     <Box
//       display="flex"
//       justifyContent="center"
//       alignItems="center"
//       height="100vh"
//     >
//       <CircularProgress />
//     </Box>
//   ) : error ? (
//     <Message variant="danger">{error}</Message>
//   ) : (
//     <>
//       <Typography variant="h4" gutterBottom>
//         Order {order._id}
//       </Typography>
//       <Box display="flex" flexDirection="row" spacing={2}>
//         <Box flex={3}>
//           <Card variant="outlined">
//             <CardContent>
//               <Typography variant="h6">Shipping</Typography>
//               <Typography>
//                 <strong>Name: </strong> {order.user.name}
//               </Typography>
//               <Typography>
//                 <strong>Email: </strong>
//                 <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
//               </Typography>
//               <Typography>
//                 <strong>Address:</strong>
//                 {order.shippingAddress.address}, {order.shippingAddress.city}{" "}
//                 {order.shippingAddress.postalCode},{" "}
//                 {order.shippingAddress.country}
//               </Typography>
//               {order.isDelivered ? (
//                 <Message variant="success">
//                   Delivered on {order.deliveredAt}
//                 </Message>
//               ) : (
//                 <Message variant="danger">Not Delivered</Message>
//               )}
//               <Divider sx={{ my: 2 }} />
//               <Typography variant="h6">Payment Method</Typography>
//               <Typography>
//                 <strong>Method: </strong>
//                 {order.paymentMethod}
//               </Typography>
//               {order.isPaid ? (
//                 <Message variant="success">Paid on {order.paidAt}</Message>
//               ) : (
//                 <Message variant="danger">Not Paid</Message>
//               )}
//               <Divider sx={{ my: 2 }} />
//               <Typography variant="h6">Order Items</Typography>
//               {order.orderItems.length === 0 ? (
//                 <Message>Order is empty</Message>
//               ) : (
//                 <List>
//                   {order.orderItems.map((item, index) => (
//                     <ListItem key={index}>
//                       <ListItemAvatar>
//                         <Avatar src={item.image} alt={item.name} />
//                       </ListItemAvatar>
//                       <ListItemText
//                         primary={
//                           <Link to={`/product/${item.product}`}>
//                             {item.name}
//                           </Link>
//                         }
//                         secondary={`${item.qty} x $${item.price} = $${
//                           item.qty * item.price
//                         }`}
//                       />
//                     </ListItem>
//                   ))}
//                 </List>
//               )}
//             </CardContent>
//           </Card>
//         </Box>
//         <Box flex={1}>
//           <Card variant="outlined">
//             <CardContent>
//               <Typography variant="h6">Order Summary</Typography>
//               <Box display="flex" justifyContent="space-between">
//                 <Typography>Items</Typography>
//                 <Typography>${order.itemsPrice}</Typography>
//               </Box>
//               <Box display="flex" justifyContent="space-between">
//                 <Typography>Shipping</Typography>
//                 <Typography>${order.shippingPrice}</Typography>
//               </Box>
//               <Box display="flex" justifyContent="space-between">
//                 <Typography>Tax</Typography>
//                 <Typography>${order.taxPrice}</Typography>
//               </Box>
//               <Box display="flex" justifyContent="space-between">
//                 <Typography>Total</Typography>
//                 <Typography>${order.totalPrice}</Typography>
//               </Box>
//               {!order.isPaid && (
//                 <Box mt={2}>
//                   {loadingPay && <CircularProgress />}
//                   {!sdkReady ? (
//                     <CircularProgress />
//                   ) : (
//                     <PayPalButton
//                       amount={order.totalPrice}
//                       onSuccess={successPaymentHandler}
//                     />
//                   )}
//                 </Box>
//               )}
//               {loadingDeliver && <CircularProgress />}
//               {userInfo &&
//                 userInfo.isAdmin &&
//                 order.isPaid &&
//                 !order.isDelivered && (
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={deliverHandler}
//                     fullWidth
//                   >
//                     Mark As Delivered
//                   </Button>
//                 )}
//             </CardContent>
//           </Card>
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default OrderScreen;
