import React from "react";
import { Modal, Button } from "react-bootstrap"; // You can use MUI or any other library

const DynamicAlert = ({
  show,
  onHide,
  title,
  message,
  onOk,
  onCancel,
  okText = "OK",
  cancelText = "Cancel",
}) => {
  return (
    <Modal show={show} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <Button
          variant="icon"
          onClick={onCancel}
          style={{ fontSize: "1.5rem", color: "black" }}
        >
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button variant="primary" onClick={onOk}>
          {okText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DynamicAlert;
