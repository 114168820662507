// import React from "react";
// import { Container, Row, Col } from "react-bootstrap";

// const Footer = () => {
//   return (
//     <footer>
//       <Container>
//         <Row>
//           <Col className="text-center py-3">Copyright &copy; Techkunstler</Col>
//         </Row>
//       </Container>
//     </footer>
//   );
// };

// export default Footer;
import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { Link } from "react-router-dom"; // if using React Router for navigation

const Footer = () => {
  return (
    <footer className=" py-4 px-4" style={{ backgroundColor: "#001F3F" }}>
      <Container>
        <Row>
          <Col md={4} className="text-center text-md-left mb-3 mb-md-0">
            <h3 style={{ color: "white" }}>Techkunstler</h3>
            <p>Copyright &copy; {new Date().getFullYear()}</p>
          </Col>
          <Col md={4} className="text-center mb-3 mb-md-0">
            <Nav className="flex-column">
              <Nav.Link as={Link} to="/terms-of-service" className="text-white">
                Terms of Service
              </Nav.Link>
              <Nav.Link as={Link} to="/privacy-policy" className="text-white">
                Privacy Policy
              </Nav.Link>
              <Nav.Link as={Link} to="/contact-us" className="text-white">
                Contact Us
              </Nav.Link>
              <Nav.Link as={Link} to="/returns" className="text-white">
                Return/Cancellations
              </Nav.Link>
            </Nav>
          </Col>
          <Col md={4} className="text-center text-md-right">
            <h5 style={{ color: "white" }}>Follow Us</h5>
            <Nav className="justify-content-center justify-content-md-end">
              <Nav.Link href="https://facebook.com" className="text-white">
                Facebook
              </Nav.Link>
              <Nav.Link href="https://twitter.com" className="text-white">
                Twitter
              </Nav.Link>
              <Nav.Link href="https://instagram.com" className="text-white">
                Instagram
              </Nav.Link>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

// import React from "react";
// import { Container, Grid, Typography } from "@mui/material";

// const Footer = () => {
//   return (
//     <footer>
//       <Container maxWidth="lg" sx={{ py: 3 }}>
//         <Grid container justifyContent="center">
//           <Grid item xs={12}>
//             <Typography variant="body2" color="textSecondary" align="center">
//               Copyright &copy; Techkunstler
//             </Typography>
//           </Grid>
//         </Grid>
//       </Container>
//     </footer>
//   );
// };

// export default Footer;
