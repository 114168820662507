// import React, { useState } from "react";
// import { Form, Button } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

// const SearchBox = ({ navigate }) => {
//   const [keyword, setKeyword] = useState("");
//   const submitHandler = (e) => {
//     e.preventDefault();
//     if (keyword.trim()) {
//       navigate(`/search/${keyword}`);
//     } else {
//       navigate("/");
//     }
//   };

//   return (
//     <Form onSubmit={submitHandler} className="d-flex">
//       <Form.Control
//         type="text"
//         name="q"
//         onChange={(e) => setKeyword(e.target.value)}
//         placeholder="Search Products..."
//         className="me-sm-2 ms-sm-5"
//       />
//       <Button type="submit" variant="outline-success" className="p-2">
//         Search
//       </Button>
//     </Form>
//   );
// };

// export default SearchBox;

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SearchBox = ({ navigate }) => {
  const [keyword, setKeyword] = useState("");

  const submitHandler = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (keyword.trim()) {
        navigate(`/search/${keyword}`);
      } else {
        navigate("/");
      }
    }
  };

  return (
    <div style={styles.searchContainer}>
      {/* <FaSearch style={styles.searchIcon} /> */}
      <i style={styles.searchIcon} className="fas fa-search"></i>
      <Form.Control
        type="text"
        name="q"
        onChange={(e) => setKeyword(e.target.value)}
        onKeyDown={submitHandler}
        value={keyword}
        placeholder="Search here" // Constantly display "Search here"
        style={styles.inputBox}
      />
    </div>
  );
};

const styles = {
  searchContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "400px", // Limit width to make it compact
    margin: "0 auto", // Center horizontally
  },
  searchIcon: {
    position: "absolute",
    top: "50%",
    left: "10px",
    transform: "translateY(-50%)",
    color: "#888", // Lighter color for the icon
    fontSize: "1.2rem",
  },
  inputBox: {
    paddingLeft: "40px", // Space for the search icon
    paddingRight: "10px",
    height: "40px",
    // borderRadius: "20px", // Rounded edges
    // border: "1px solid black",
    outline: "none",
    width: "100%",
    fontSize: "1rem",
    color: "#333", // Text color
    placeholderTextColor: "#666", // Placeholder color
  },
};

export default SearchBox;

// import React, { useState } from "react";
// import { Box, TextField, Button } from "@mui/material";
// import { useNavigate } from "react-router-dom";

// const SearchBox = () => {
//   const [keyword, setKeyword] = useState("");
//   const navigate = useNavigate();

//   const submitHandler = (e) => {
//     e.preventDefault();
//     if (keyword.trim()) {
//       navigate(`/search/${keyword}`);
//     } else {
//       navigate("/");
//     }
//   };

//   return (
//     <Box
//       component="form"
//       onSubmit={submitHandler}
//       display="flex"
//       alignItems="center"
//     >
//       <TextField
//         variant="outlined"
//         size="small"
//         label="Search Products..."
//         onChange={(e) => setKeyword(e.target.value)}
//         sx={{ mr: 2, flexGrow: 1 }}
//       />
//       <Button type="submit" variant="outlined" color="primary">
//         Search
//       </Button>
//     </Box>
//   );
// };

// export default SearchBox;
