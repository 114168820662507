import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { register } from "../actions/userActions";

const RegisterScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(register(name, email, password));
    }
  };

  return (
    <FormContainer>
      <h1>Sign Up</h1>
      {message && <Message variant="danger">{message}</Message>}
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="name"
            placeholder="Enter name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type="submit" variant="primary">
          Register
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
          Have an Account?{" "}
          <Link to={redirect ? `/login?redirect=${redirect}` : "/login"}>
            Login
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default RegisterScreen;

// import React, { useState, useEffect } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import {
//   Grid,
//   Typography,
//   TextField,
//   Button,
//   Paper,
//   Snackbar,
//   Alert,
// } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import { register } from "../actions/userActions";
// import Loader from "../components/Loader";
// import FormContainer from "../components/FormContainer";

// const RegisterScreen = () => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [message, setMessage] = useState(null);

//   const dispatch = useDispatch();
//   const location = useLocation();
//   const navigate = useNavigate();

//   const userRegister = useSelector((state) => state.userRegister);
//   const { loading, error, userInfo } = userRegister;

//   const redirect = location.search ? location.search.split("=")[1] : "/";

//   useEffect(() => {
//     if (userInfo) {
//       navigate(redirect);
//     }
//   }, [navigate, userInfo, redirect]);

//   const submitHandler = (e) => {
//     e.preventDefault();
//     if (password !== confirmPassword) {
//       setMessage("Passwords do not match");
//     } else {
//       dispatch(register(name, email, password));
//     }
//   };

//   return (
//     <FormContainer>
//       <Typography variant="h4" gutterBottom>
//         Sign Up
//       </Typography>
//       {message && (
//         <Snackbar
//           open={true}
//           autoHideDuration={6000}
//           onClose={() => setMessage(null)}
//         >
//           <Alert onClose={() => setMessage(null)} severity="error">
//             {message}
//           </Alert>
//         </Snackbar>
//       )}
//       {error && (
//         <Snackbar
//           open={true}
//           autoHideDuration={6000}
//           onClose={() => setMessage(null)}
//         >
//           <Alert onClose={() => setMessage(null)} severity="error">
//             {error}
//           </Alert>
//         </Snackbar>
//       )}
//       {loading && <Loader />}
//       <Paper style={{ padding: "20px" }}>
//         <form onSubmit={submitHandler}>
//           <TextField
//             fullWidth
//             margin="normal"
//             label="Name"
//             variant="outlined"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//           />
//           <TextField
//             fullWidth
//             margin="normal"
//             label="Email Address"
//             variant="outlined"
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//           />
//           <TextField
//             fullWidth
//             margin="normal"
//             label="Password"
//             variant="outlined"
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//           />
//           <TextField
//             fullWidth
//             margin="normal"
//             label="Confirm Password"
//             variant="outlined"
//             type="password"
//             value={confirmPassword}
//             onChange={(e) => setConfirmPassword(e.target.value)}
//           />
//           <Button type="submit" variant="contained" color="primary" fullWidth>
//             Register
//           </Button>
//         </form>
//       </Paper>
//       <Grid container justifyContent="center" style={{ marginTop: "16px" }}>
//         <Grid item>
//           Have an Account?{" "}
//           <Link to={redirect ? `/login?redirect=${redirect}` : "/login"}>
//             Login
//           </Link>
//         </Grid>
//       </Grid>
//     </FormContainer>
//   );
// };

// export default RegisterScreen;
