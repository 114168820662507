import React, { useState } from "react";
import { Form, Button, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";
import { savePaymentMethod } from "../actions/cartActions";
import { useNavigate } from "react-router-dom";

const PaymentScreen = () => {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;
  const navigate = useNavigate();

  if (!shippingAddress.address) {
    navigate("/shipping");
  }

  const [paymentMethod, setPaymentMethod] = useState("PayPal");

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));
    navigate("/placeorder");
  };

  return (
    <FormContainer style={{ margin: "0px 40px" }}>
      <CheckoutSteps step1 step2 step3 />
      <h1>Payment Method</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group>
          <Form.Label as="legend">Select Method</Form.Label>
          <Col>
            <Form.Check
              type="radio"
              label="PayPal or Credit Card"
              id="PayPal"
              name="paymentMethod"
              value="PayPal"
              checked
              onChange={(e) => setPaymentMethod(e.target.value)}
            ></Form.Check>
            {/* <Form.Check
              type='radio'
              label='Stripe'
              id='Stripe'
              name='paymentMethod'
              value='Stripe'
              onChange={(e) => setPaymentMethod(e.target.value)}
            ></Form.Check> */}
          </Col>
        </Form.Group>

        <Button type="submit" variant="primary" style={{ marginTop: "10px" }}>
          Continue
        </Button>
      </Form>
    </FormContainer>
  );
};

export default PaymentScreen;

// import React, { useState } from "react";
// import {
//   Box,
//   Typography,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
//   Button,
//   FormControl,
//   FormLabel,
// } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import FormContainer from "../components/FormContainer";
// import CheckoutSteps from "../components/CheckoutSteps";
// import { savePaymentMethod } from "../actions/cartActions";
// import { useNavigate } from "react-router-dom";

// const PaymentScreen = () => {
//   const cart = useSelector((state) => state.cart);
//   const { shippingAddress } = cart;
//   const navigate = useNavigate();

//   if (!shippingAddress.address) {
//     navigate("/shipping");
//   }

//   const [paymentMethod, setPaymentMethod] = useState("PayPal");

//   const dispatch = useDispatch();

//   const submitHandler = (e) => {
//     e.preventDefault();
//     dispatch(savePaymentMethod(paymentMethod));
//     navigate("/placeorder");
//   };

//   return (
//     <FormContainer>
//       <CheckoutSteps step1 step2 step3 />
//       <Typography variant="h4" gutterBottom>
//         Payment Method
//       </Typography>
//       <Box component="form" onSubmit={submitHandler} noValidate>
//         <FormControl component="fieldset" fullWidth>
//           <FormLabel component="legend">Select Method</FormLabel>
//           <RadioGroup
//             aria-label="payment method"
//             name="paymentMethod"
//             value={paymentMethod}
//             onChange={(e) => setPaymentMethod(e.target.value)}
//           >
//             <FormControlLabel
//               value="PayPal"
//               control={<Radio />}
//               label="PayPal or Credit Card"
//             />
//             {/* Uncomment the following lines to add Stripe option */}
//             {/* <FormControlLabel
//               value="Stripe"
//               control={<Radio />}
//               label="Stripe"
//             /> */}
//           </RadioGroup>
//         </FormControl>
//         <Button
//           type="submit"
//           variant="contained"
//           color="primary"
//           sx={{ mt: 2 }}
//         >
//           Continue
//         </Button>
//       </Box>
//     </FormContainer>
//   );
// };

// export default PaymentScreen;
