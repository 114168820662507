import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
} from "react-bootstrap";
import Rating from "../components/Rating";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Meta from "../components/Meta";
import {
  listProductDetails,
  createProductReview,
} from "../actions/productActions";
import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstants";

const ProductScreen = () => {
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    success: successProductReview,
    loading: loadingProductReview,
    error: errorProductReview,
  } = productReviewCreate;

  useEffect(() => {
    if (successProductReview) {
      setRating(0);
      setComment("");
    }
    if (!product._id || product._id !== id) {
      dispatch(listProductDetails(id));
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }
  }, [dispatch, id, successProductReview]);

  const addToCartHandler = () => {
    navigate(`/cart/${id}?qty=${qty}`);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProductReview(id, {
        rating,
        comment,
      })
    );
  };
  console.log("product", product);

  return (
    <>
      <Link
        className="btn btn-light my-3"
        to="/"
        style={{ margin: "0px 40px" }}
      >
        <i className="fas fa-chevron-left"></i>
        {/* Go Back */}
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div style={{ margin: "0px 40px" }}>
          <Meta title={product.name} />
          <Row>
            <Col md={6}>
              <Image
                src={product.image}
                alt={product.name}
                fluid
                style={{ borderRadius: "40px", border: "solid 2px black" }}
              />
            </Col>
            <Col md={3}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h3>{product.name}</h3>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Rating
                    value={product.rating}
                    text={`${product.numReviews} reviews`}
                  />
                </ListGroup.Item>
                <ListGroup.Item>Price: INR {product.price}</ListGroup.Item>
                <ListGroup.Item>
                  Description: {product.description}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={3}>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col>Price:</Col>
                      <Col>
                        <strong>INR {product.price}</strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Row>
                      <Col>Status:</Col>
                      <Col>
                        {product.countInStock > 0 ? "In Stock" : "Out Of Stock"}
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  {product.countInStock > 0 && (
                    <ListGroup.Item>
                      <Row>
                        <Col>Qty</Col>
                        <Col>
                          <Form.Control
                            as="select"
                            value={qty}
                            onChange={(e) => setQty(e.target.value)}
                          >
                            {[...Array(product.countInStock).keys()].map(
                              (x) => (
                                <option key={x + 1} value={x + 1}>
                                  {x + 1}
                                </option>
                              )
                            )}
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}

                  <ListGroup.Item>
                    <Button
                      onClick={addToCartHandler}
                      className="btn-block"
                      type="button"
                      disabled={product.countInStock === 0}
                    >
                      Add To Cart
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h2>Reviews</h2>
              {product.reviews.length === 0 && <Message>No Reviews</Message>}
              <ListGroup variant="flush">
                {product.reviews.map((review) => (
                  <ListGroup.Item key={review._id}>
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <p>{review.createdAt.substring(0, 10)}</p>
                    <p>{review.comment}</p>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item>
                  <h2>Write a Customer Review</h2>
                  {successProductReview && (
                    <Message variant="success">
                      Review submitted successfully
                    </Message>
                  )}
                  {loadingProductReview && <Loader />}
                  {errorProductReview && (
                    <Message variant="danger">{errorProductReview}</Message>
                  )}
                  {userInfo ? (
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId="rating">
                        <Form.Label>Rating</Form.Label>
                        <Form.Control
                          as="select"
                          value={rating}
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value="">Select...</option>
                          <option value="1">1 - Poor</option>
                          <option value="2">2 - Fair</option>
                          <option value="3">3 - Good</option>
                          <option value="4">4 - Very Good</option>
                          <option value="5">5 - Excellent</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="comment">
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                          as="textarea"
                          row="3"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Button
                        disabled={loadingProductReview}
                        type="submit"
                        variant="primary"
                        style={{ marginTop: "10px" }}
                      >
                        Submit
                      </Button>
                    </Form>
                  ) : (
                    <Message>
                      Please <Link to="/login">sign in</Link> to write a review{" "}
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ProductScreen;

// import React, { useState, useEffect } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   Box,
//   Button,
//   Card,
//   CircularProgress,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
//   Typography,
//   TextField,
//   Grid,
//   Paper,
//   Divider,
//   List,
//   ListItem,
//   ListItemText,
// } from "@mui/material";
// import Rating from "../components/Rating";
// import Message from "../components/Message";
// import Meta from "../components/Meta";
// import {
//   listProductDetails,
//   createProductReview,
// } from "../actions/productActions";
// import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstants";

// const ProductScreen = () => {
//   const [qty, setQty] = useState(1);
//   const [rating, setRating] = useState(0);
//   const [comment, setComment] = useState("");
//   const { id } = useParams();

//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const productDetails = useSelector((state) => state.productDetails);
//   const { loading, error, product } = productDetails;

//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;

//   const productReviewCreate = useSelector((state) => state.productReviewCreate);
//   const {
//     success: successProductReview,
//     loading: loadingProductReview,
//     error: errorProductReview,
//   } = productReviewCreate;

//   useEffect(() => {
//     if (successProductReview) {
//       setRating(0);
//       setComment("");
//     }
//     if (!product._id || product._id !== id) {
//       dispatch(listProductDetails(id));
//       dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
//     }
//   }, [dispatch, id, successProductReview, product._id]);

//   const addToCartHandler = () => {
//     navigate(`/cart/${id}?qty=${qty}`);
//   };

//   const submitHandler = (e) => {
//     e.preventDefault();
//     dispatch(
//       createProductReview(id, {
//         rating,
//         comment,
//       })
//     );
//   };

//   return (
//     <>
//       <Link to="/" style={{ textDecoration: "none" }}>
//         <Button variant="outlined" color="primary" style={{ margin: "16px 0" }}>
//           Go Back
//         </Button>
//       </Link>
//       {loading ? (
//         <CircularProgress />
//       ) : error ? (
//         <Message variant="error">{error}</Message>
//       ) : (
//         <>
//           <Meta title={product.name} />
//           <Grid container spacing={3}>
//             <Grid item md={6}>
//               <img
//                 src={product.image}
//                 alt={product.name}
//                 style={{ width: "100%", height: "auto" }}
//               />
//             </Grid>
//             <Grid item md={3}>
//               <Box>
//                 <Typography variant="h5">{product.name}</Typography>
//                 <Rating
//                   value={product.rating}
//                   text={`${product.numReviews} reviews`}
//                 />
//                 <Typography variant="h6">Price: ${product.price}</Typography>
//                 <Typography variant="body1">
//                   Description: {product.description}
//                 </Typography>
//               </Box>
//             </Grid>
//             <Grid item md={3}>
//               <Card variant="outlined">
//                 <Box padding={2}>
//                   <Typography variant="h6">Price: ${product.price}</Typography>
//                   <Typography variant="h6">
//                     Status:{" "}
//                     {product.countInStock > 0 ? "In Stock" : "Out Of Stock"}
//                   </Typography>
//                   {product.countInStock > 0 && (
//                     <FormControl
//                       fullWidth
//                       variant="outlined"
//                       style={{ marginTop: "16px" }}
//                     >
//                       <InputLabel>Qty</InputLabel>
//                       <Select
//                         value={qty}
//                         onChange={(e) => setQty(e.target.value)}
//                         label="Qty"
//                       >
//                         {[...Array(product.countInStock).keys()].map((x) => (
//                           <MenuItem key={x + 1} value={x + 1}>
//                             {x + 1}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   )}
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     fullWidth
//                     onClick={addToCartHandler}
//                     disabled={product.countInStock === 0}
//                     style={{ marginTop: "16px" }}
//                   >
//                     Add To Cart
//                   </Button>
//                 </Box>
//               </Card>
//             </Grid>
//           </Grid>
//           <Box marginTop={4}>
//             <Typography variant="h6">Reviews</Typography>
//             {product.reviews.length === 0 && <Message>No Reviews</Message>}
//             <List>
//               {product.reviews.map((review) => (
//                 <ListItem key={review._id} divider>
//                   <ListItemText
//                     primary={<strong>{review.name}</strong>}
//                     secondary={
//                       <>
//                         <Rating value={review.rating} />
//                         <Typography variant="body2">
//                           {review.createdAt.substring(0, 10)}
//                         </Typography>
//                         <Typography variant="body1">
//                           {review.comment}
//                         </Typography>
//                       </>
//                     }
//                   />
//                 </ListItem>
//               ))}
//               <ListItem>
//                 <Box width="100%">
//                   <Typography variant="h6">Write a Customer Review</Typography>
//                   {successProductReview && (
//                     <Message variant="success">
//                       Review submitted successfully
//                     </Message>
//                   )}
//                   {loadingProductReview && <CircularProgress />}
//                   {errorProductReview && (
//                     <Message variant="error">{errorProductReview}</Message>
//                   )}
//                   {userInfo ? (
//                     <form onSubmit={submitHandler}>
//                       <FormControl
//                         fullWidth
//                         variant="outlined"
//                         style={{ marginTop: "16px" }}
//                       >
//                         <InputLabel>Rating</InputLabel>
//                         <Select
//                           value={rating}
//                           onChange={(e) => setRating(e.target.value)}
//                           label="Rating"
//                         >
//                           <MenuItem value="">Select...</MenuItem>
//                           <MenuItem value="1">1 - Poor</MenuItem>
//                           <MenuItem value="2">2 - Fair</MenuItem>
//                           <MenuItem value="3">3 - Good</MenuItem>
//                           <MenuItem value="4">4 - Very Good</MenuItem>
//                           <MenuItem value="5">5 - Excellent</MenuItem>
//                         </Select>
//                       </FormControl>
//                       <FormControl
//                         fullWidth
//                         variant="outlined"
//                         style={{ marginTop: "16px" }}
//                       >
//                         <TextField
//                           label="Comment"
//                           multiline
//                           rows={3}
//                           variant="outlined"
//                           value={comment}
//                           onChange={(e) => setComment(e.target.value)}
//                         />
//                       </FormControl>
//                       <Button
//                         type="submit"
//                         variant="contained"
//                         color="primary"
//                         disabled={loadingProductReview}
//                         style={{ marginTop: "16px" }}
//                       >
//                         Submit
//                       </Button>
//                     </form>
//                   ) : (
//                     <Message>
//                       Please <Link to="/login">sign in</Link> to write a review
//                     </Message>
//                   )}
//                 </Box>
//               </ListItem>
//             </List>
//           </Box>
//         </>
//       )}
//     </>
//   );
// };

// export default ProductScreen;
