import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Carousel, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import Message from "./Message";
import { listTopProducts } from "../actions/productActions";

const ProductCarousel = () => {
  const dispatch = useDispatch();

  const productTopRated = useSelector((state) => state.productTopRated);
  const { loading, error, products } = productTopRated;

  useEffect(() => {
    dispatch(listTopProducts());
  }, [dispatch]);

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <Carousel
      pause="hover"
      indicators={false}
      // className="bg-dark product-carousel"
      style={{
        backgroundColor: "#B0B0B0",
        margin: "0 0.5px",
        borderRadius: "20px",
      }}
    >
      {products.map((product) => (
        <Carousel.Item key={product._id}>
          <Link to={`/product/${product._id}`}>
            <Image
              src={product.image}
              alt={product.name}
              fluid
              className="carousel-image"
              // style={{  }}
            />
            <Carousel.Caption className="carousel-caption">
              <h2 style={{ color: "black" }}>
                {product.name} (INR {product.price})
              </h2>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ProductCarousel;

// import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import { Typography, Box } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import { Carousel, Image } from "react-bootstrap";
// import Loader from "./Loader";
// import Message from "./Message";
// import { listTopProducts } from "../actions/productActions";

// const ProductCarousel = () => {
//   const dispatch = useDispatch();

//   const productTopRated = useSelector((state) => state.productTopRated);
//   const { loading, error, products } = productTopRated;

//   useEffect(() => {
//     dispatch(listTopProducts());
//   }, [dispatch]);

//   return loading ? (
//     <Loader />
//   ) : error ? (
//     <Message variant="danger">{error}</Message>
//   ) : (
//     <Carousel
//       sx={{ backgroundColor: "background.default" }}
//       indicatorContainerProps={{ sx: { display: "none" } }} // Optional: Hide indicators
//     >
//       {products.map((product) => (
//         <Carousel.Item key={product._id}>
//           <Link to={`/product/${product._id}`}>
//             <img
//               src={product.image}
//               alt={product.name}
//               style={{ width: "100%", height: "auto" }}
//             />
//             <Box
//               sx={{
//                 position: "absolute",
//                 bottom: 0,
//                 left: 0,
//                 right: 0,
//                 background: "rgba(0, 0, 0, 0.5)",
//                 color: "white",
//                 textAlign: "center",
//                 p: 2,
//               }}
//             >
//               <Typography variant="h6">
//                 {product.name} (${product.price})
//               </Typography>
//             </Box>
//           </Link>
//         </Carousel.Item>
//       ))}
//     </Carousel>
//   );
// };

// export default ProductCarousel;
