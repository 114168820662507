import React from "react";

const Rating = ({ value, text, color }) => {
  return (
    <div className="rating">
      <span>
        <i
          style={{ color }}
          className={
            value >= 1
              ? "fas fa-star"
              : value >= 0.5
              ? "fas fa-star-half-alt"
              : "far fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          style={{ color }}
          className={
            value >= 2
              ? "fas fa-star"
              : value >= 1.5
              ? "fas fa-star-half-alt"
              : "far fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          style={{ color }}
          className={
            value >= 3
              ? "fas fa-star"
              : value >= 2.5
              ? "fas fa-star-half-alt"
              : "far fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          style={{ color }}
          className={
            value >= 4
              ? "fas fa-star"
              : value >= 3.5
              ? "fas fa-star-half-alt"
              : "far fa-star"
          }
        ></i>
      </span>
      <span>
        <i
          style={{ color }}
          className={
            value >= 5
              ? "fas fa-star"
              : value >= 4.5
              ? "fas fa-star-half-alt"
              : "far fa-star"
          }
        ></i>
      </span>
      <span>{text && text}</span>
    </div>
  );
};

Rating.defaultProps = {
  color: "#f8e825",
};

export default Rating;

// import React from "react";
// import { Box, Typography } from "@mui/material";
// import StarIcon from "@mui/icons-material/Star";
// import StarHalfIcon from "@mui/icons-material/StarHalf";
// import StarBorderIcon from "@mui/icons-material/StarBorder";

// const Rating = ({ value, text, color }) => {
//   return (
//     <Box display="flex" alignItems="center">
//       {[...Array(5)].map((_, index) => {
//         const ratingValue = index + 1;
//         return (
//           <span key={ratingValue}>
//             {value >= ratingValue ? (
//               <StarIcon sx={{ color }} />
//             ) : value >= ratingValue - 0.5 ? (
//               <StarHalfIcon sx={{ color }} />
//             ) : (
//               <StarBorderIcon sx={{ color }} />
//             )}
//           </span>
//         );
//       })}
//       {text && (
//         <Typography variant="body2" sx={{ ml: 1 }}>
//           {text}
//         </Typography>
//       )}
//     </Box>
//   );
// };

// Rating.defaultProps = {
//   color: "#f8e825",
// };

// export default Rating;
