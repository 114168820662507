import React, { useState, useEffect } from "react";
import { Table, Form, Button, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { getUserDetails, updateUserProfile } from "../actions/userActions";
import { listMyOrders } from "../actions/orderActions";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import { useNavigate } from "react-router-dom";

const ProfileScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      if (!user || !user.name || success) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails("profile"));
        dispatch(listMyOrders());
      } else {
        setName(user.name);
        setEmail(user.email);
      }
    }
  }, [dispatch, navigate, userInfo, user, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(updateUserProfile({ id: user._id, name, email, password }));
    }
  };

  return (
    <Row>
      <Col md={3}>
        <h2 style={{ margin: "0px 40px" }}>User Profile</h2>
        {message && <Message variant="danger">{message}</Message>}
        {}
        {success && <Message variant="success">Profile Updated</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <div style={{ margin: "0px 40px" }}>
            <Form onSubmit={submitHandler}>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Button type="submit" variant="primary">
                Update
              </Button>
            </Form>
          </div>
        )}
      </Col>
      <Col md={9}>
        <h2 style={{ margin: "0px 40px" }}>My Orders</h2>
        {loadingOrders ? (
          <Loader />
        ) : errorOrders ? (
          <Message variant="danger">{errorOrders}</Message>
        ) : (
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>DATE</th>
                <th>TOTAL</th>
                <th>PAID</th>
                <th>DELIVERED</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order._id}>
                  <td>{order._id}</td>
                  <td>{order.createdAt.substring(0, 10)}</td>
                  <td>{order.totalPrice}</td>
                  <td>
                    {order.isPaid ? (
                      order.paidAt.substring(0, 10)
                    ) : (
                      <i className="fas fa-times" style={{ color: "red" }}></i>
                    )}
                  </td>
                  <td>
                    {order.isDelivered ? (
                      order.deliveredAt.substring(0, 10)
                    ) : (
                      <i className="fas fa-times" style={{ color: "red" }}></i>
                    )}
                  </td>
                  <td>
                    <LinkContainer to={`/order/${order._id}`}>
                      <Button className="btn-sm" variant="light">
                        Details
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Col>
    </Row>
  );
};

export default ProfileScreen;

// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import {
//   TextField,
//   Button,
//   Typography,
//   Grid,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Snackbar,
//   Alert,
// } from "@mui/material";
// import { getUserDetails, updateUserProfile } from "../actions/userActions";
// import { listMyOrders } from "../actions/orderActions";
// import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
// import Loader from "../components/Loader";

// const ProfileScreen = () => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [message, setMessage] = useState(null);

//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const userDetails = useSelector((state) => state.userDetails);
//   const { loading, error, user } = userDetails;

//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;

//   const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
//   const { success } = userUpdateProfile;

//   const orderListMy = useSelector((state) => state.orderListMy);
//   const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

//   useEffect(() => {
//     if (!userInfo) {
//       navigate("/login");
//     } else {
//       if (!user || !user.name || success) {
//         dispatch({ type: USER_UPDATE_PROFILE_RESET });
//         dispatch(getUserDetails("profile"));
//         dispatch(listMyOrders());
//       } else {
//         setName(user.name);
//         setEmail(user.email);
//       }
//     }
//   }, [dispatch, navigate, userInfo, user, success]);

//   const submitHandler = (e) => {
//     e.preventDefault();
//     if (password !== confirmPassword) {
//       setMessage("Passwords do not match");
//     } else {
//       dispatch(updateUserProfile({ id: user._id, name, email, password }));
//     }
//   };

//   return (
//     <Grid container spacing={3}>
//       <Grid item md={3}>
//         <Typography variant="h5">User Profile</Typography>
//         {message && (
//           <Snackbar
//             open={true}
//             autoHideDuration={6000}
//             onClose={() => setMessage(null)}
//           >
//             <Alert onClose={() => setMessage(null)} severity="error">
//               {message}
//             </Alert>
//           </Snackbar>
//         )}
//         {success && (
//           <Snackbar
//             open={true}
//             autoHideDuration={6000}
//             onClose={() => setMessage(null)}
//           >
//             <Alert onClose={() => setMessage(null)} severity="success">
//               Profile Updated
//             </Alert>
//           </Snackbar>
//         )}
//         {loading ? (
//           <Loader />
//         ) : error ? (
//           <Snackbar
//             open={true}
//             autoHideDuration={6000}
//             onClose={() => setMessage(null)}
//           >
//             <Alert onClose={() => setMessage(null)} severity="error">
//               {error}
//             </Alert>
//           </Snackbar>
//         ) : (
//           <form onSubmit={submitHandler}>
//             <TextField
//               fullWidth
//               margin="normal"
//               label="Name"
//               variant="outlined"
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//             />
//             <TextField
//               fullWidth
//               margin="normal"
//               label="Email Address"
//               variant="outlined"
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//             <TextField
//               fullWidth
//               margin="normal"
//               label="Password"
//               variant="outlined"
//               type="password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//             />
//             <TextField
//               fullWidth
//               margin="normal"
//               label="Confirm Password"
//               variant="outlined"
//               type="password"
//               value={confirmPassword}
//               onChange={(e) => setConfirmPassword(e.target.value)}
//             />
//             <Button type="submit" variant="contained" color="primary" fullWidth>
//               Update
//             </Button>
//           </form>
//         )}
//       </Grid>
//       <Grid item md={9}>
//         <Typography variant="h5">My Orders</Typography>
//         {loadingOrders ? (
//           <Loader />
//         ) : errorOrders ? (
//           <Snackbar
//             open={true}
//             autoHideDuration={6000}
//             onClose={() => setMessage(null)}
//           >
//             <Alert onClose={() => setMessage(null)} severity="error">
//               {errorOrders}
//             </Alert>
//           </Snackbar>
//         ) : (
//           <TableContainer component={Paper}>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell>ID</TableCell>
//                   <TableCell>DATE</TableCell>
//                   <TableCell>TOTAL</TableCell>
//                   <TableCell>PAID</TableCell>
//                   <TableCell>DELIVERED</TableCell>
//                   <TableCell></TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {orders.map((order) => (
//                   <TableRow key={order._id}>
//                     <TableCell>{order._id}</TableCell>
//                     <TableCell>{order.createdAt.substring(0, 10)}</TableCell>
//                     <TableCell>${order.totalPrice}</TableCell>
//                     <TableCell>
//                       {order.isPaid ? (
//                         order.paidAt.substring(0, 10)
//                       ) : (
//                         <i
//                           className="fas fa-times"
//                           style={{ color: "red" }}
//                         ></i>
//                       )}
//                     </TableCell>
//                     <TableCell>
//                       {order.isDelivered ? (
//                         order.deliveredAt.substring(0, 10)
//                       ) : (
//                         <i
//                           className="fas fa-times"
//                           style={{ color: "red" }}
//                         ></i>
//                       )}
//                     </TableCell>
//                     <TableCell>
//                       <Button
//                         variant="outlined"
//                         color="primary"
//                         href={`/order/${order._id}`}
//                       >
//                         Details
//                       </Button>
//                     </TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         )}
//       </Grid>
//     </Grid>
//   );
// };

// export default ProfileScreen;
