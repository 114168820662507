import React from "react";

const ContactUsScreen = () => {
  return (
    <div style={{ margin: "0 40px" }}>
      <h1>Address</h1>
      <p>
        Tech Kunstler, #2 AND 3, Janai, Sir M V layout, 6th block, ullala,
        Bangalore 560110
      </p>
      <h2>Email</h2>
      <p>Contact@techkunstler.com</p>
    </div>
  );
};

export default ContactUsScreen;
