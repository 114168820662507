import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = () => {
  return (
    <Spinner
      animation="border"
      role="status"
      style={{
        width: "100px",
        height: "100px",
        margin: "auto",
        display: "block",
      }}
    >
      <span className="sr-only">Loading...</span>
    </Spinner>
  );
};

export default Loader;

// import React from "react";
// import { CircularProgress, Box } from "@mui/material";

// const Loader = () => {
//   return (
//     <Box
//       sx={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         width: "100px",
//         height: "100px",
//         margin: "auto",
//       }}
//     >
//       <CircularProgress size={100} />
//     </Box>
//   );
// };

// export default Loader;
