import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Row, Col, ListGroup, Image, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import CheckoutSteps from "../components/CheckoutSteps";
import { createOrder } from "../actions/orderActions";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { USER_DETAILS_RESET } from "../constants/userConstants";

const PlaceOrderScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);

  if (!cart.shippingAddress.address) {
    navigate("/shipping");
  } else if (!cart.paymentMethod) {
    navigate("/payment");
  }
  //   Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  );
  cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 100);
  cart.taxPrice = addDecimals(Number((0.15 * cart.itemsPrice).toFixed(2)));
  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2);

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;

  useEffect(() => {
    if (success) {
      navigate(`/order/${order._id}`);
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: ORDER_CREATE_RESET });
    }
    // eslint-disable-next-line
  }, [navigate, success]);

  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
      })
    );
  };

  return (
    <div style={{ margin: "0px 40px" }}>
      <CheckoutSteps step1 step2 step3 step4 />
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Shipping</h2>
              <p>
                <strong>Address:</strong>
                {cart.shippingAddress.address}, {cart.shippingAddress.city}{" "}
                {cart.shippingAddress.postalCode},{" "}
                {cart.shippingAddress.country}
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <strong>Method: </strong>
              {cart.paymentMethod}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Order Items</h2>
              {cart.cartItems.length === 0 ? (
                <Message>Your cart is empty</Message>
              ) : (
                <ListGroup variant="flush">
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x INR {item.price} = INR
                          {item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Items</Col>
                  <Col>INR {cart.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Shipping</Col>
                  <Col>INR{cart.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Tax</Col>
                  <Col>INR{cart.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col>INR {cart.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                {error && <Message variant="danger">{error}</Message>}
              </ListGroup.Item>
              <ListGroup.Item>
                <Button
                  type="button"
                  className="btn-block"
                  disabled={cart.cartItems === 0}
                  onClick={placeOrderHandler}
                >
                  Place Order
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PlaceOrderScreen;

// import React, { useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { Box, Typography, Button, Grid, Paper } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import Message from "../components/Message";
// import CheckoutSteps from "../components/CheckoutSteps";
// import { createOrder } from "../actions/orderActions";
// import { ORDER_CREATE_RESET } from "../constants/orderConstants";
// import { USER_DETAILS_RESET } from "../constants/userConstants";

// const PlaceOrderScreen = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const cart = useSelector((state) => state.cart);

//   if (!cart.shippingAddress.address) {
//     navigate("/shipping");
//   } else if (!cart.paymentMethod) {
//     navigate("/payment");
//   }

//   // Calculate prices
//   const addDecimals = (num) => {
//     return (Math.round(num * 100) / 100).toFixed(2);
//   };

//   cart.itemsPrice = addDecimals(
//     cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
//   );
//   cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 100);
//   cart.taxPrice = addDecimals(Number((0.15 * cart.itemsPrice).toFixed(2)));
//   cart.totalPrice = (
//     Number(cart.itemsPrice) +
//     Number(cart.shippingPrice) +
//     Number(cart.taxPrice)
//   ).toFixed(2);

//   const orderCreate = useSelector((state) => state.orderCreate);
//   const { order, success, error } = orderCreate;

//   useEffect(() => {
//     if (success) {
//       navigate(`/order/${order._id}`);
//       dispatch({ type: USER_DETAILS_RESET });
//       dispatch({ type: ORDER_CREATE_RESET });
//     }
//     // eslint-disable-next-line
//   }, [navigate, success]);

//   const placeOrderHandler = () => {
//     dispatch(
//       createOrder({
//         orderItems: cart.cartItems,
//         shippingAddress: cart.shippingAddress,
//         paymentMethod: cart.paymentMethod,
//         itemsPrice: cart.itemsPrice,
//         shippingPrice: cart.shippingPrice,
//         taxPrice: cart.taxPrice,
//         totalPrice: cart.totalPrice,
//       })
//     );
//   };

//   return (
//     <>
//       <CheckoutSteps step1 step2 step3 step4 />
//       <Grid container spacing={3}>
//         <Grid item md={8}>
//           <Paper elevation={3} sx={{ padding: 2 }}>
//             <Typography variant="h6" gutterBottom>
//               Shipping
//             </Typography>
//             <Typography>
//               <strong>Address:</strong> {cart.shippingAddress.address},{" "}
//               {cart.shippingAddress.city} {cart.shippingAddress.postalCode},{" "}
//               {cart.shippingAddress.country}
//             </Typography>

//             <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
//               Payment Method
//             </Typography>
//             <Typography>
//               <strong>Method:</strong> {cart.paymentMethod}
//             </Typography>

//             <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
//               Order Items
//             </Typography>
//             {cart.cartItems.length === 0 ? (
//               <Message>Your cart is empty</Message>
//             ) : (
//               cart.cartItems.map((item, index) => (
//                 <Box key={index} sx={{ mb: 2 }}>
//                   <Grid container spacing={2}>
//                     <Grid item md={1}>
//                       <img
//                         src={item.image}
//                         alt={item.name}
//                         style={{ width: "100%", borderRadius: "4px" }}
//                       />
//                     </Grid>
//                     <Grid item md={6}>
//                       <Link to={`/product/${item.product}`}>{item.name}</Link>
//                     </Grid>
//                     <Grid item md={5}>
//                       {item.qty} x ${item.price} = ${item.qty * item.price}
//                     </Grid>
//                   </Grid>
//                 </Box>
//               ))
//             )}
//           </Paper>
//         </Grid>
//         <Grid item md={4}>
//           <Paper elevation={3} sx={{ padding: 2 }}>
//             <Typography variant="h6" gutterBottom>
//               Order Summary
//             </Typography>
//             <Grid container spacing={1}>
//               <Grid item xs={6}>
//                 Items
//               </Grid>
//               <Grid item xs={6} textAlign="right">
//                 ${cart.itemsPrice}
//               </Grid>
//               <Grid item xs={6}>
//                 Shipping
//               </Grid>
//               <Grid item xs={6} textAlign="right">
//                 ${cart.shippingPrice}
//               </Grid>
//               <Grid item xs={6}>
//                 Tax
//               </Grid>
//               <Grid item xs={6} textAlign="right">
//                 ${cart.taxPrice}
//               </Grid>
//               <Grid item xs={6}>
//                 <strong>Total</strong>
//               </Grid>
//               <Grid item xs={6} textAlign="right">
//                 <strong>${cart.totalPrice}</strong>
//               </Grid>
//             </Grid>
//             {error && <Message variant="error">{error}</Message>}
//             <Button
//               variant="contained"
//               color="primary"
//               fullWidth
//               sx={{ mt: 2 }}
//               disabled={cart.cartItems.length === 0}
//               onClick={placeOrderHandler}
//             >
//               Place Order
//             </Button>
//           </Paper>
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export default PlaceOrderScreen;
