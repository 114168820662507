import React from "react";
import { Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import SearchBox from "./SearchBox";
import { logout } from "../actions/userActions";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    // <header style={{ height: "15vh", backgroundColor: "white" }}>
    //   <Navbar collapseOnSelect fixed="top">
    //     <Container>
    //       <LinkContainer to="/">
    //         <Navbar.Brand className="navbar-brand" bsPrefix="navbar-brand">
    //           Techkunstler
    //         </Navbar.Brand>
    //       </LinkContainer>
    //       <Navbar.Toggle aria-controls="basic-navbar-nav" />
    //       <Navbar.Collapse id="basic-navbar-nav">
    //         {/* <SearchBox navigate={navigate} /> */}
    //         <Nav className="ml-auto">
    //           <SearchBox navigate={navigate} />
    //           <LinkContainer to="/cart">
    //             <Nav.Link>
    //               <i
    //                 style={{ fontSize: "2rem", color: "#333" }}
    //                 className="fa fa-shopping-bag"
    //               ></i>
    //             </Nav.Link>
    //           </LinkContainer>
    //           {userInfo ? (
    //             <NavDropdown
    //               title={
    //                 <i
    //                   style={{ fontSize: "2rem", color: "#333" }}
    //                   className="fa fa-user"
    //                 ></i>
    //               }
    //               id="username"
    //             >
    //               <LinkContainer to="/profile">
    //                 <NavDropdown.Item>Profile</NavDropdown.Item>
    //               </LinkContainer>
    //               <NavDropdown.Item onClick={logoutHandler}>
    //                 Logout
    //               </NavDropdown.Item>
    //             </NavDropdown>
    //           ) : (
    //             <LinkContainer to="/login">
    //               <Nav.Link>
    //                 <i className="fa fa-user"></i> Sign In
    //               </Nav.Link>
    //             </LinkContainer>
    //           )}
    //           {userInfo && userInfo.isAdmin && (
    //             <NavDropdown
    //               title={
    //                 <i
    //                   style={{ fontSize: "2rem", color: "#333" }}
    //                   className="fas fa-users-cog"
    //                 ></i>
    //               }
    //               id="adminmenu"
    //             >
    //               <LinkContainer to="/admin/userlist">
    //                 <NavDropdown.Item>Users</NavDropdown.Item>
    //               </LinkContainer>
    //               <LinkContainer to="/admin/productlist">
    //                 <NavDropdown.Item>Products</NavDropdown.Item>
    //               </LinkContainer>
    //               <LinkContainer to="/admin/orderlist">
    //                 <NavDropdown.Item>Orders</NavDropdown.Item>
    //               </LinkContainer>
    //             </NavDropdown>
    //           )}
    //         </Nav>
    //       </Navbar.Collapse>
    //     </Container>
    //   </Navbar>
    // </header>
    <header
      style={{
        height: "15vh",
        backgroundColor: "#001F3F",
        // borderBottom: "1px solid black",
        // position: "fixed",
        // width: "100%",
        // zIndex: "1000",
      }}
    >
      <Navbar
        collapseOnSelect
        fixed="top"
        style={{
          backgroundColor: "#001F3F",
          width: "100%",
          margin: 0,
          // paddingBottom: 0,
          borderBottom: "1px solid #001F3F",
        }}
      >
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand className="navbar-brand" bsPrefix="navbar-brand">
              Techkunstler
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto d-flex align-items-center">
              {/* SearchBox */}
              <div style={{ marginRight: "20px" }}>
                <SearchBox navigate={navigate} />
              </div>

              {/* Cart Icon */}
              <LinkContainer to="/cart">
                <Nav.Link>
                  <i
                    style={{
                      fontSize: "2rem",
                      color: "white",
                      marginRight: "20px",
                    }}
                    className="fa fa-shopping-bag"
                  ></i>
                </Nav.Link>
              </LinkContainer>

              {/* User Icon */}
              {userInfo ? (
                <NavDropdown
                  title={
                    <i
                      style={{
                        fontSize: "2rem",
                        color: "white",
                        // marginRight: "20px",
                      }}
                      className="fa fa-user"
                    ></i>
                  }
                  id="username"
                  style={{ marginRight: 0 }}
                >
                  <LinkContainer to="/profile">
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to="/login" style={{ color: "white" }}>
                  <Nav.Link>
                    <i className="fa fa-user"></i> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}

              {/* Admin Icon */}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown
                  title={
                    <i
                      style={{
                        fontSize: "2rem",
                        color: "white",
                        // marginLeft: "20px",
                      }}
                      className="fas fa-users-cog"
                    ></i>
                  }
                  id="adminmenu"
                >
                  <LinkContainer to="/admin/userlist">
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/admin/productlist">
                    <NavDropdown.Item>Products</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/admin/orderlist">
                    <NavDropdown.Item>Orders</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;

// import React from "react";
// import {
//   AppBar,
//   Toolbar,
//   IconButton,
//   Typography,
//   Menu,
//   MenuItem,
//   Button,
//   Box,
// } from "@mui/material";
// import { ShoppingCart, AccountCircle } from "@mui/icons-material";
// import { Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { logout } from "../actions/userActions";
// import SearchBox from "./SearchBox";

// const Header = () => {
//   const dispatch = useDispatch();

//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;

//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [adminAnchorEl, setAdminAnchorEl] = React.useState(null);

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleAdminMenuOpen = (event) => {
//     setAdminAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const handleAdminMenuClose = () => {
//     setAdminAnchorEl(null);
//   };

//   const logoutHandler = () => {
//     handleMenuClose();
//     dispatch(logout());
//   };

//   return (
//     <AppBar position="static" color="primary">
//       <Toolbar>
//         <Typography
//           variant="h6"
//           component={Link}
//           to="/"
//           sx={{ flexGrow: 1, textDecoration: "none", color: "inherit" }}
//         >
//           Techkunstler
//         </Typography>
//         <SearchBox />
//         <Box sx={{ display: "flex", alignItems: "center" }}>
//           <IconButton component={Link} to="/cart" color="inherit">
//             <ShoppingCart />
//           </IconButton>
//           {userInfo ? (
//             <>
//               <Button
//                 color="inherit"
//                 startIcon={<AccountCircle />}
//                 onClick={handleMenuOpen}
//               >
//                 {userInfo.name}
//               </Button>
//               <Menu
//                 anchorEl={anchorEl}
//                 open={Boolean(anchorEl)}
//                 onClose={handleMenuClose}
//               >
//                 <MenuItem
//                   component={Link}
//                   to="/profile"
//                   onClick={handleMenuClose}
//                 >
//                   Profile
//                 </MenuItem>
//                 <MenuItem onClick={logoutHandler}>Logout</MenuItem>
//               </Menu>
//             </>
//           ) : (
//             <Button component={Link} to="/login" color="inherit">
//               <AccountCircle />
//               Sign In
//             </Button>
//           )}
//           {userInfo && userInfo.isAdmin && (
//             <>
//               <Button color="inherit" onClick={handleAdminMenuOpen}>
//                 Admin
//               </Button>
//               <Menu
//                 anchorEl={adminAnchorEl}
//                 open={Boolean(adminAnchorEl)}
//                 onClose={handleAdminMenuClose}
//               >
//                 <MenuItem
//                   component={Link}
//                   to="/admin/userlist"
//                   onClick={handleAdminMenuClose}
//                 >
//                   Users
//                 </MenuItem>
//                 <MenuItem
//                   component={Link}
//                   to="/admin/productlist"
//                   onClick={handleAdminMenuClose}
//                 >
//                   Products
//                 </MenuItem>
//                 <MenuItem
//                   component={Link}
//                   to="/admin/orderlist"
//                   onClick={handleAdminMenuClose}
//                 >
//                   Orders
//                 </MenuItem>
//               </Menu>
//             </>
//           )}
//         </Box>
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default Header;
