import React from "react";
import { Card, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Rating from "./Rating"; // Assuming you have a Rating component

const Product = ({ product }) => {
  const navigate = useNavigate();
  const addToCartHandler = () => {
    navigate(`/cart/${product._id}?qty=1`);
  };
  return (
    <Card className="my-3 p-3 rounded" style={{ border: "1px solid #008080" }}>
      <Link to={`/product/${product._id}`}>
        <Card.Img src={product.image} variant="top" />
      </Link>

      <Card.Body
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // backgroundColor: "#B0B0B0",
          marginTop: "10px",
        }}
      >
        <Link to={`/product/${product._id}`}>
          <Card.Title as="div">
            <strong style={{ fontSize: "20px" }}>{product.name}</strong>
          </Card.Title>
        </Link>

        <Card.Text as="div">
          <Rating
            color="#FFCC00"
            value={product.rating}
            text={`${product.numReviews} reviews`}
          />
        </Card.Text>

        <Card.Text as="h3">INR {product.price}</Card.Text>

        <Button
          style={{
            backgroundColor: "#FF6600",
          }}
          onClick={addToCartHandler}
          disabled={product.countInStock === 0}
        >
          Add to Cart
        </Button>
        {product.countInStock === 0 && <Card.Text>Out of stock!</Card.Text>}
      </Card.Body>
    </Card>
  );
};

export default Product;

// import React from "react";
// import { Link } from "react-router-dom";
// import { Card } from "@mui/material";
// import Rating from "./Rating";

// const Product = ({ product }) => {
//   return (
//     <Card sx={{ my: 3, p: 3, borderRadius: 2 }}>
//       <Link to={`/product/${product._id}`}>
//         <Card.Media
//           component="img"
//           image={product.image}
//           alt={product.name}
//           sx={{ borderRadius: 1 }}
//         />
//       </Link>

//       <Card.Content>
//         <Link to={`/product/${product._id}`}>
//           <Card.Title variant="h6">{product.name}</Card.Title>
//         </Link>

//         <Card.Text>
//           <Rating
//             value={product.rating}
//             text={`${product.numReviews} reviews`}
//           />
//         </Card.Text>

//         <Card.Text sx={{ fontSize: "1.25rem" }}>${product.price}</Card.Text>
//       </Card.Content>
//     </Card>
//   );
// };

// export default Product;
